<template>
  <div class="main row">
    <div class="col-md-12">
      <b-row class="mb-5 justify-content-end">
        <b-col md="2">
          <b-input-group>
            <date-picker
              placeholder="Từ"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="fromDate"
            ></date-picker>
          </b-input-group>
        </b-col>
        <b-col md="2">
          <b-input-group>
            <date-picker
              placeholder="Đến"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="toDate"
            ></date-picker>
          </b-input-group>
        </b-col>
        <b-col md="1">
          <b-button
            style="width: 70px"
            variant="primary"
            size="sm"
            @click="fetchMainData()"
            >Lọc
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div class="col-md-6">
      <KTCard ref="preview" v-bind:example="true" class="gutter-b">
        <template v-slot:body>
          <div
            class="card-body d-flex flex-column p-0"
            style="position: relative;"
          >
            <!--begin::Stats-->
            <div class="flex-grow-1 card-spacer-x">
              <div class="d-flex align-items-center justify-content-between">
                <div class="col-3">
                  <i class="fas fa-people-arrows icon-4x text-danger"></i>
                </div>
                <div class="col-9">
                  <div class="font-weight-bold">
                    Tổng số điểm tiêu dùng đã chuyển
                  </div>
                  <div class="font-weight-bolder font-size-h3">
                    {{ totalPointSuccess || 0 }} điểm
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </KTCard>
    </div>
    <div class="col-md-6">
      <KTCard ref="preview" v-bind:example="true" class="gutter-b">
        <template v-slot:body>
          <div
            class="card-body d-flex flex-column p-0"
            style="position: relative;"
          >
            <!--begin::Stats-->
            <div class="flex-grow-1 card-spacer-x">
              <div class="d-flex align-items-center justify-content-between">
                <div class="col-3">
                  <i class="far fa-check-circle icon-4x text-success"></i>
                </div>
                <div class="col-9">
                  <div class="font-weight-bold">
                    Tổng số yêu cầu chuyển điểm đã duyệt
                  </div>
                  <div class="font-weight-bolder font-size-h3">
                    {{ totalRequestSuccess }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </KTCard>
    </div>
    <div class="col-md-6">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header border-0 pl-4 pr-4">
          <!-- <h3 class="card-title font-weight-bolder text-dark">

          </h3> -->
          <div class="card-toolbar w-100">
            <div class="topbar-item w-100">
              <!--begin::Header-->
              <div
                class=" offcanvas-header offcanvas-header-navsd-flex align-items-center justify-content-between mb-5"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="col-6">
                    <ul
                      class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          v-on:click="setActiveTab"
                          data-tab="0"
                          data-toggle="tab"
                          href="javascript:void(0)"
                          role="tab"
                          aria-selected="true"
                        >
                          Khách chuyển điểm
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          v-on:click="setActiveTab"
                          data-tab="1"
                          data-toggle="tab"
                          href="javascript:void(0)"
                          role="tab"
                          aria-selected="false"
                        >
                          Khách nhận điểm
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <b-form-select
                      class="select-style"
                      size="sm"
                      v-model="orderByCustomerTransfer"
                      :options="optionOrderBy"
                      @change="fetchOrderByTransferCustomer"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <!--end::Header-->
              <div class="offcanvas-content px-5">
                <div class="tab-content">
                  <b-tabs class="hide-tabs" v-model="tabIndex">
                    <b-tab active>
                      <div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
                        <div class="mb-15">
                          <div
                            class="d-flex align-items-center flex-wrap mb-5"
                          >
                            <div class="col-md-4">
                              <span
                                class="text-dark text-hover-primary font-size-h5 font-weight-bold"
                              >
                                Khách hàng
                              </span>
                            </div>
                            <div class="col-md-4 text-center">
                              <span
                                class="text-dark text-hover-primary font-size-h5 font-weight-bold"
                              >
                                Số lần chuyển
                              </span>
                            </div>
                            <div class="col-md-4 text-center">
                              <span
                                class="text-dark text-hover-primary font-size-h5 font-weight-bold"
                              >
                                Tổng điểm
                              </span>
                            </div>
                          </div>
                          <template v-for="(item, i) in customersPointTransfer">
                            <!--begin: Item -->
                            <div
                              class="d-flex align-items-center flex-wrap mb-5"
                              v-bind:key="i"
                            >
                              <div
                                class="d-flex align-items-center col-12 col-md-4"
                              >
                                <div class="ml-4">
                                  <div
                                    class="text-dark-75 font-weight-bolder font-size-lg mb-0"
                                  >
                                    {{
                                      item.infoCustomerTransfer.fullName || '--'
                                    }}
                                  </div>
                                  <div class="text-muted font-weight-bold">
                                    {{
                                      item.infoCustomerTransfer.phoneNo || '--'
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div
                                class="d-flex justify-content-center col-12 col-md-4 text-center"
                              >
                                <span
                                  class="text-danger font-weight-bolder font-size-lg py-1 my-lg-0 my-2"
                                >
                                  {{ item.count }}
                                </span>
                              </div>
                              <div
                                class="d-flex justify-content-center font-size-lg col-12 col-md-4 text-center"
                              >
                                <span
                                  class="text-danger font-weight-bolder py-1 my-lg-0 my-2"
                                >
                                  {{ item.totalPointTransfer }}
                                </span>
                              </div>
                            </div>
                            <!--end: Item -->
                          </template>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
                        <div class="mb-15">
                          <div
                            class="d-flex align-items-center flex-wrap mb-5"
                          >
                            <div class="col-md-4">
                              <span
                                class="text-dark font-size-h5 font-weight-bold"
                              >
                                Khách hàng
                              </span>
                            </div>
                            <div class="col-md-4 text-center">
                              <span
                                class="text-dark font-size-h5 font-weight-bold"
                              >
                                Số lần nhận
                              </span>
                            </div>
                            <div class="col-md-4 text-center">
                              <span
                                class="text-dark font-size-h5 font-weight-bold"
                              >
                                Tổng điểm
                              </span>
                            </div>
                          </div>
                          <template v-for="(item, i) in customersPointReceive">
                            <!--begin: Item -->
                            <div
                              class="d-flex align-items-center flex-wrap mb-5"
                              v-bind:key="i"
                            >
                              <div
                                class="d-flex align-items-center col-12 col-md-4"
                              >
                                <div class="ml-4">
                                  <div
                                    class="text-dark-75 font-weight-bolder font-size-lg mb-0"
                                  >
                                    {{
                                      item.infoCustomerReceive.fullName || '--'
                                    }}
                                  </div>
                                  <div class="text-muted font-weight-bold">
                                    {{
                                      item.infoCustomerReceive.phoneNo || '--'
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div
                                class="d-flex justify-content-center col-12 col-md-4 text-center"
                              >
                                <span
                                  class="text-primary font-weight-bolder font-size-lg py-1 my-lg-0 my-2"
                                >
                                  {{ item.count }}
                                </span>
                              </div>
                              <div
                                class="d-flex justify-content-center font-size-lg col-12 col-md-4 text-center"
                              >
                                <span
                                  class="text-primary font-weight-bolder py-1 my-lg-0 my-2"
                                >
                                  {{ item.totalPointReceive }}
                                </span>
                              </div>
                            </div>
                            <!--end: Item -->
                          </template>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-4">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header border-0 pl-4 pr-4">
          <h3 class="card-title font-weight-bolder text-dark">
            Khách hàng chuyển điểm
          </h3>
          <div class="card-toolbar">
            <b-form-select
              class="select-style"
              size="sm"
              v-model="orderByCustomerTransfer"
              :options="optionOrderBy"
              @change="fetchOrderByTransferCustomer"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
        </div>
        <hr class="p-0 m-0 mb-2" />
        <div class="card-body pt-2">
          <template v-for="(item, i) in customersPointTransfer">
            <div
              class="d-flex align-items-center justify-content-between mb-5"
              v-bind:key="i"
            >
              <div
                class="d-flex flex-column flex-grow-1 w-50"
                v-if="item.infoCustomerTransfer"
              >
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 "
                >
                  {{ item.infoCustomerTransfer.fullName || '--' }}
                </span>
                <div>
                  {{ item.infoCustomerTransfer.phoneNo || '--' }}
                </div>
              </div>
              <div class="d-flex flex-column flex-grow-1 w-50" v-if="item">
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                >
                  Số lần chuyển
                </span>
                <div>
                  <span class="text-danger font-weight-bold">{{
                    item.count || '--'
                  }}</span>
                  - Tổng:
                  <span class="text-danger font-weight-bold">{{
                    item.totalPointTransfer
                  }}</span>
                  điểm
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-md-4">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header border-0 pl-4 pr-4">
          <h3 class="card-title font-weight-bolder text-dark">
            Khách hàng nhận điểm
          </h3>
          <div class="card-toolbar">
            <b-form-select
              class="select-style"
              size="sm"
              v-model="orderByCustomerReceive"
              :options="optionOrderBy"
              @change="fetchOrderByTransferReceive"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
        </div>
        <hr class="p-0 m-0 mb-2" />
        <div class="card-body pt-2">
          <template v-for="(item, i) in customersPointReceive">
            <div
              class="d-flex align-items-center justify-content-between mb-5"
              v-bind:key="i"
            >
              <div
                class="d-flex flex-column flex-grow-1 w-50"
                v-if="item.infoCustomerReceive"
              >
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 "
                >
                  {{ item.infoCustomerReceive.fullName || '--' }}
                </span>
                <div>
                  {{ item.infoCustomerReceive.phoneNo || '--' }}
                </div>
              </div>
              <div class="d-flex flex-column flex-grow-1 w-50" v-if="item">
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                >
                  Số lần nhận
                </span>
                <div>
                  <span class="text-danger font-weight-bold">{{
                    item.count || '--'
                  }}</span>
                  - Tổng:
                  <span class="text-danger font-weight-bold">{{
                    item.totalPointReceive
                  }}</span>
                  điểm
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-md-6">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header border-0 pl-4 pr-4">
          <h3 class="card-title font-weight-bolder text-dark">
            Nhân viên tạo phiếu
          </h3>
          <div class="card-toolbar">
            <b-form-select
              class="select-style"
              size="sm"
              v-model="orderByStaffCreateTransferPoint"
              :options="optionOrderBy"
              @change="fetchOrderByStaffTransfer"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
        </div>
        <hr class="p-0 m-0 mb-2" />
        <div class="card-body pt-2">
          <template v-for="(item, i) in staffCreatePointReceive">
            <div
              class="d-flex align-items-center justify-content-between mb-5"
              v-bind:key="i"
            >
              <div
                class="d-flex flex-column flex-grow-1 w-50"
                v-if="item.infoUserCreated"
              >
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 "
                >
                  {{ item.infoUserCreated.employee.fullName || '--' }}
                </span>
                <div>
                  {{ item.infoUserCreated.employee.code || '--' }}
                </div>
              </div>
              <div class="d-flex flex-column flex-grow-1 w-50" v-if="item">
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                >
                  Số lần tạo
                </span>
                <div>
                  <span class="text-danger font-weight-bold">{{
                    item.count || '--'
                  }}</span>
                  - Tổng:
                  <span class="text-danger font-weight-bold">{{
                    item.totalPointTransfer
                  }}</span>
                  điểm
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div> -->
    <div class="col-md-6">
      <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pl-4 pr-4">
          <h3 class="card-title font-weight-bolder text-dark">
            Nhân viên tạo phiếu
          </h3>
          <div class="card-toolbar">
            <b-form-select
              class="select-style"
              size="sm"
              v-model="orderByStaffCreateTransferPoint"
              :options="optionOrderByForStaff"
              @change="fetchOrderByStaffTransfer"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
        </div>
        <hr class="p-0 m-0 mb-2" />
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-2">
          <!-- <template v-for="(item, i) in staffCreatePointReceive">
            <div
              class="d-flex align-items-center justify-content-between mb-5"
              v-bind:key="i"
            >
              <div
                class="d-flex flex-column flex-grow-1 w-50"
                v-if="item.infoUserCreated"
              >
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 "
                >
                  {{ item.infoUserCreated.employee.fullName || '--' }}
                </span>
                <div>
                  {{ item.infoUserCreated.employee.code || '--' }}
                </div>
              </div>
              <div class="d-flex flex-column flex-grow-1 w-50" v-if="item">
                <span
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                >
                  Số lần tạo
                </span>
                <div>
                  <span class="text-danger font-weight-bold">{{
                    item.count || '--'
                  }}</span>
                  - Tổng:
                  <span class="text-danger font-weight-bold">{{
                    item.totalPointTransfer
                  }}</span>
                  điểm
                </div>
              </div>
            </div>
          </template> -->

          <div class="d-flex align-items-center flex-wrap mb-5">
            <div class="col-md-4">
              <span
                class="text-dark text-hover-primary font-size-h5 font-weight-bold"
              >
                Nhân viên
              </span>
            </div>
            <div class="col-md-4 text-center">
              <span
                class="text-dark text-hover-primary font-size-h5 font-weight-bold"
              >
                Số lần tạo
              </span>
            </div>
            <div class="col-md-4 text-center">
              <span
                class="text-dark text-hover-primary font-size-h5 font-weight-bold"
              >
                Tổng điểm
              </span>
            </div>
          </div>
          <template v-for="(item, i) in staffCreatePointReceive">
            <!--begin: Item -->
            <div
              class="d-flex align-items-center flex-wrap mb-5"
              v-bind:key="i"
            >
              <div class="d-flex align-items-center col-12 col-md-4">
                <div class="ml-4">
                  <div
                    class="text-dark-75 font-weight-bolder font-size-lg mb-0"
                  >
                    {{ item.infoUserCreated.employee.fullName || '--' }}
                  </div>
                  <div class="text-muted font-weight-bold">
                    {{ item.infoUserCreated.employee.code || '--' }}
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-center col-12 col-md-4 text-center"
              >
                <span
                  class="text-danger font-weight-bolder font-size-lg py-1 my-lg-0 my-2"
                >
                  {{ item.count }}
                </span>
              </div>
              <div
                class="d-flex justify-content-center font-size-lg col-12 col-md-4 text-center"
              >
                <span class="text-danger font-weight-bolder py-1 my-lg-0 my-2">
                  {{ item.totalPointTransfer }}
                </span>
              </div>
            </div>
            <!--end: Item -->
          </template>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main ::v-deep {
  .nav-tabs {
    border-bottom: unset;
  }
  .tab-content .nav-tabs {
    display: none;
  }
  .vue-treeselect__control {
    border-radius: none;
    height: 2.5rem;
  }
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  input.form-control {
    border: 1px solid #ced4da;
  }
  .icon-color {
    color: #464e5f;
  }
}
</style>
<script>
import KTCard from '@/view/content/Card.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { checkPermission } from '../../../utils/saveDataToLocal';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import { DATE_FORMAT, DATE_DB_FORMAT } from '@/utils/constants';
import { makeToastFaile } from '@/utils/common';

export default {
  data() {
    return {
      tabIndex: 0,
      totalPointSuccess: 0,
      totalRequestSuccess: 0,
      orderByCustomerTransfer: 'REQUEST_OR_RECEIVE_TOTAL',
      orderByCustomerReceive: 'REQUEST_OR_RECEIVE_TOTAL',
      orderByStaffCreateTransferPoint: 'REQUEST_OR_RECEIVE_TOTAL',
      optionOrderBy: [
        {
          id: 'POINT_TOTAL',
          name: 'Tổng điểm',
        },
        {
          id: 'REQUEST_OR_RECEIVE_TOTAL',
          name: 'Số lần chuyển | nhận',
        },
      ],
      optionOrderByForStaff: [
        {
          id: 'POINT_TOTAL',
          name: 'Tổng điểm',
        },
        {
          id: 'REQUEST_OR_RECEIVE_TOTAL',
          name: 'Số lần tạo',
        },
      ],
      fromDate: moment()
        .startOf('month')
        .format(DATE_FORMAT),
      toDate: moment()
        .endOf('month')
        .format(DATE_FORMAT),
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      storeOptions: [
        {
          id: -1,
          label: 'Chọn cửa hàng',
        },
      ],
      onLoading: false,
      customersPointTransfer: [],
      customersPointReceive: [],
      staffCreatePointReceive: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      numberOfPage: 1,
    };
  },
  components: {
    KTCard,
    datePicker,
  },
  created() {
    this.fetchDataCustomerReceive();
    this.fetchDataCustomerTransfer();
    this.fetchDataStaffTransfer();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thống kê phiếu chuyển điểm thành công' },
    ]);
  },
  watch: {},
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('.nav-link');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active');
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute('data-tab'));

      // set current active tab
      event.target.classList.add('active');
    },
    checkPermission,
    fetchMainData: function() {
      this.fetchDataCustomerReceive();
      this.fetchDataCustomerTransfer();
      this.fetchDataStaffTransfer();
    },
    fetchOrderByTransferCustomer() {
      this.fetchDataCustomerTransfer();
    },
    fetchOrderByTransferReceive() {
      this.fetchDataCustomerReceive();
    },
    fetchOrderByStaffTransfer() {
      this.fetchDataStaffTransfer();
    },

    fetchDataCustomerReceive: function() {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;
      const params = {
        fromDate: moment(this.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT),
        toDate: moment(this.toDate, DATE_FORMAT).format(DATE_DB_FORMAT),
        limit: 10,
        page: this.page,
        orderBy: this.orderByCustomerReceive,
      };
      ApiService.query('customer/statistical-receive-transfer-point', {
        params: params,
      }).then(({ data }) => {
        if (data.status) {
          this.customersPointReceive = [];
          const dataReq = data.data;
          this.customersPointReceive = dataReq.dataset;
          this.numberOfPage = dataReq.totalPage;
        } else {
          makeToastFaile(data.message);
        }
        this.onLoading = false;
      });
    },
    fetchDataCustomerTransfer: function() {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      const params = {
        fromDate: moment(this.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT),
        toDate: moment(this.toDate, DATE_FORMAT).format(DATE_DB_FORMAT),
        limit: 10,
        page: this.page,
        orderBy: this.orderByCustomerTransfer,
      };

      ApiService.query('customer/statistical-transfer-point', {
        params: params,
      }).then(({ data }) => {
        if (data.status) {
          this.customersPointTransfer = [];
          const dataReq = data.data;
          this.customersPointTransfer = dataReq.dataset;
          this.numberOfPage = dataReq.totalPage;
        } else {
          makeToastFaile(data.message);
        }
        this.onLoading = false;
      });
    },
    fetchDataStaffTransfer: function() {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      const params = {
        fromDate: moment(this.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT),
        toDate: moment(this.toDate, DATE_FORMAT).format(DATE_DB_FORMAT),
        limit: 10,
        page: this.page,
        orderBy: this.orderByStaffCreateTransferPoint,
      };

      ApiService.query('customer/statistical-staff-transfer-point', {
        params: params,
      }).then(({ data }) => {
        if (data.status) {
          this.staffCreatePointReceive = [];
          const dataReq = data.data;
          this.staffCreatePointReceive = dataReq.dataset;
          this.totalRequestSuccess = dataReq.totalRequestSuccess;
          this.totalPointSuccess = dataReq.totalPointSuccess;
        } else {
          makeToastFaile(data.message);
        }
        this.onLoading = false;
      });
    },
    editItem: function(item) {
      this.$router.push({
        name: 'customer-transfer-update',
        query: { id: item.id },
      });
    },
  },
};
</script>
